import './App.css';
import Home from './Components/Home';
import NavBar from "./Components/Navabar";
import React from 'react';

function App() {

  return (
    <div className='app'>
      <nav className="fixed top-0 z-50 w-full">
        <NavBar />
      </nav> 
      <Home></Home>



      
    </div>
  );
}

export default App;
