import React from "react";
import "../Component_CSS/Home.css"
import { Link } from 'react-scroll';
import About from "./About";
import Projects from "./Projects";
function Home() {  
  return (
    <div className="">
      <div className="flex flex-col items-center h-screen pt-10 Page " name="/" >
        <div className="pt-24 text-center md:p-16">  
          <h1 className="text-4xl text-white md:text-7xl name">SHRESHTH KUMAR</h1>
          <h2 className="mt-6 text-2xl md:text-4xl text-gold" >
            Full Stack Developer
          </h2>
        </div>

        {/* <Link activeClass="active" to="About" spy={true} smooth={true} offset={-50} duration={500}  href="#About" className="px-12 py-4 rounded-md mt-60 hover:text-white bg-gold">
            <img style={{width: 24}} src="./images/downArrow.svg" className="animate-bounce" alt="icon"></img>
        </Link> */}
      </div>
      <div name="About" >
        <About></About>
      </div>
      <div name="Projects">
        <Projects></Projects>
      </div>
    </div>
  );
}

export default Home;
