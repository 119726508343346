import React, { useState } from 'react';
import { Link } from 'react-scroll';
import "../Component_CSS/Nav.css"

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleHamburger = () => {
    setIsOpen(!isOpen)
  }
  return ( 
    <div className="realtive">
      <div className='flex justify-between px-5 bg-black '>
        <Link to="/"  spy={true} smooth={true} offset={-100} duration={500} className="flex justify-center py-4">
          <img src='./images/sk_logo.png' className='w-16 md:w-24' alt='logo'></img>
        </Link>
        <div className='flex flex-col items-center justify-center cursor-pointer w-1/8 lg:hidden hamburger ' onClick={toggleHamburger}>
          <div className='w-full px-6 mb-1 rounded-lg h-1/6 bg-sand'></div>
          <div className='w-full px-6 mb-1 rounded-lg h-1/6 bg-darksand'></div>
          <div className='w-full px-6 rounded-lg h-1/6 bg-sand'></div>
        </div>
        <div className='justify-between hidden w-1/2 py-4 text-lg font-semibold lg:flex links' >
          <Link to="/"  activeClass='active' spy={true} smooth={true} offset={-100} duration={500} className="py-2 cursor-pointer nav-item">
          <h1>Home</h1>
          </Link>
          <Link to="About"  activeClass='active'  spy={true} smooth={true} offset={-50} duration={500} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
            <h1>About</h1>
          </Link>
          <Link  to="Projects" activeClass='active' spy={true} smooth={true} offset={0} duration={500} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Projects</h1>
          </Link>
          <Link  to="Gaming" activeClass='active' spy={true} smooth={true} offset={0} duration={500} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Gaming</h1>
          </Link>
          <Link  to="Photography" activeClass='active' spy={true} smooth={true} offset={0} duration={500} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Photography</h1>
          </Link>
        </div>
      </div>

      <div className={`${isOpen ? "fixed" : "hidden"} top-50 md:hidden left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-80 text-gold`} >
        <div className='flex flex-col items-center justify-center'>
          <Link to="/"  activeClass='active' spy={true} smooth={true} offset={-100} duration={500}  onClick={toggleHamburger} className="py-2 cursor-pointer nav-item">
          <h1>Home</h1>
          </Link>
          <Link to="About"  activeClass='active'  spy={true} smooth={true} offset={-50} duration={500} onClick={toggleHamburger} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
            <h1>About</h1>
          </Link>
          <Link  to="Projects" activeClass='active' spy={true} smooth={true} offset={0} duration={500} onClick={toggleHamburger} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Projects</h1>
          </Link>
          <Link  to="Gaming" activeClass='active' spy={true} smooth={true} offset={0} duration={500} onClick={toggleHamburger} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Gaming</h1>
          </Link>
          <Link  to="Photography" activeClass='active' spy={true} smooth={true} offset={0} duration={500} onClick={toggleHamburger} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Photography</h1>
          </Link>
          <Link  activeClass='active' to="Hockey" spy={true} smooth={true} offset={0} duration={500} onClick={toggleHamburger} className="py-2 cursor-pointer black hover:animate-pulse nav-item">
          <h1>Hockey</h1>
          </Link>
        </div>
      </div>


      
    </div>
  );
}

export default NavBar;
