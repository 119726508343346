import "../Component_CSS/About.css"
import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import logoFinder from "../Helpers/techHelper"

function About (props) {

    const [emailOpen, setEmailOpen] = useState(false)
    const form = useRef();
    const handleDownload = () => {
        const pdfUrl = "https://drive.google.com/uc?export=download&id=1_xMZ_g2GWur0SOYlC9gcLlT1O1d3sMkQ";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Shreshth Resume.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleEmail = (e) => {
        e.preventDefault()
        document.querySelector("form").reset()
        setEmailOpen(!emailOpen)
    }

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_m4ei0pc', 'template_qus5pxb', form.current, 'GCEYaGc6eHqjPB2BT')
        .then((result) => {
            console.log(result.text);
            console.log("message sent!")
            handleDownload()
        }, (error) => {
            console.log(error.text);
            console.log("error sending message, try again!")
            alert("Something went wrong, please send your email to shreshthsingh01@gmail.com, apologies for the inconvinence")
        });
        ;
    }
    let techStack = ["CSharp", "CSS", "HTML", "Java", "NodeJS", "PHP", "Python", "React", "SQL", "Tailwind", "DotNet", "GCP", "Mongo"];

    return(
        <div className="relative z-0 flex flex-col items-center justify-center h-full bg-black md:min-h-screen aboutPage">  

           <div className={`w-full md:w-1/3 bg-black border-4 rounded-lg h-1/2 email ${emailOpen ? "absolute z-10" : "hidden"}`}>
                <span className="absolute text-3xl font-bold cursor-pointer text-gold top-5 right-5 close" onClick={handleEmail}>X</span>
                <form ref={form} className="w-full h-full form">
                    <div className="w-full mt-8">
                        <label className="px-6 text-gold">Your Email</label>
                        <input name="user-email" className="w-1/2 h-1/8" type="text" placeholder="example@mail.com"  />
                    </div>
                    <div className="w-full mt-8">
                        <label className="px-6 text-gold">Subject</label>
                        <input name="subject" className="w-1/2 h-1/8" type="text" placeholder="Hello!"  />
                    </div>
                    <div className="w-full mt-8">
                        <p><label htmlFor="body" className="px-8 text-gold">Body</label></p>
                        <textarea className="ml-24" name="message" rows={10} cols={63}  placeholder="Luke, I am your father" ></textarea>
                    </div>
                    <div className="flex w-full justify-evenly">
                        <button className="px-6 py-4 mt-4 text-black rounded-md bg-sand hover:text-white" type="submit" onClick={sendEmail}>Send</button>
                        <button className="px-6 py-4 mt-4 text-black rounded-md cursor-pointer bg-sand hover:text-white" type="clear" onClick={handleEmail}>Cancel</button>
                    </div>
                </form>
            </div>
         

            <div className="flex flex-col items-center justify-center md:flex-row largeContainer">
                <div className="flex flex-col items-center w-full md:w-1/2 pl-18 picContainer" >
                    <img className='w-3/4 rounded-tl-3xl rounded-br-3xl pic ' src='./images/me.jpg' alt='Shreshth' />
                    <div className="flex w-3/4 mt-10 socialMedia justify-evenly">
                        <a href="https://www.instagram.com/shreshthk1106/" target="_blank" rel="noreferrer"className="insta"><img  src="./images/insta.png" alt="icon"></img></a>
                        <a href="https://www.facebook.com/shreshthk1" target="_blank" rel="noreferrer" className="face"><img src="./images/facebook.png" alt="icon"></img></a>
                        <a href="https://www.linkedin.com/in/shreshth-kumar-230688187/" target="_blank" rel="noreferrer" className="linked"><img src="./images/linkedin.png" alt="icon"></img></a>
                        <a href="https://github.com/Shreshthk1" target="_blank" rel="noreferrer" className="git"><img src="./images/github.png" alt="icon"></img></a>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-4/5 infoContainer ">
                    <div className='text-center md:text-justify about'>    
                        <h1 className="pb-4 mt-6 text-3xl text-gold">About Me</h1>  
                        <h1 className='text-xl text-sand '>I'm Shreshth</h1>
                        <p className='w-full text-justify text-white md:pr-12'> 
                            <p>Welcome to my digital space! </p>
                            <p> Growing up, I witnessed firsthand the transformative power of coding and computer literacy as my parents imparted their knowledge to eager learners. <br>
                            </br>Focusing on web development and data analysis, I aquired my Bachelors degree in Computer Infromation Systems in Fall of 2023 with multiple honor roll awards, projects made using industry standards and lots of memories.  </p>
                            <p className="mt-4">Looking ahead, my aspirations include pursuing a Master's degree in Computer Science. I am driven by the desire to delve deeper into the intricacies of technology, with a long-term goal of sharing my expertise and enthusiasm with budding minds. I envision myself contributing to the world of education, guiding new students through the fascinating realms of programming and computing</p>
                            <p>Fueled by my passion for programming, I've seamlessly navigated diverse project landscapes, adapting like water to different shapes and challenges. Beyond the lines of code, you'll discover my love for video games, the excitement of hockey and cricket matches, and my exploration of interests like photography, cooking, and construction.</p>
                            <p className="mt-4">In collaboration, I am like water - flexible and able to blend seamlessly with others. I strongly believe that everyone I work with is not just a colleague but my best friend. I am a versatile individual eager to contribute my skills and forge lasting connections in the ever-evolving world of technology.</p>
                        </p>
                    </div>
                    <div className='flex flex-col h-auto text-white techStack md:block md:mt-10'>
                        <div className='flex items-center justify-center w-full mt-5 md:justify-start stackHeader md:mt-auto'>
                            <div className='flex flex-col items-start justify-center '>
                                <h1 className='w-full text-3xl text-center text-gold md:w-auto md:text-auto'>My Tech Stack</h1>
                                <h3 className='text-sand'>Technologies I've been working with lately</h3>
                            </div>
                        </div>
                        {logoFinder(techStack, "flex flex-wrap w-full mt-10 md:h-10 justify-evenly stackContent", "flex flex-col items-center justify-center", "text-center text-sand animate-pulse" )}
                    </div> 
                    <div className="flex flex-col justify-center mt-5 text-white md:justify-evenly infoButtons md:flex-row md:mt-20">
                        <div className="flex flex-col mb-4 contactButton md:mb-0">
                            <p className='text-xl text-center md:text-auto'>Have a cool project in mind?</p>
                            <button className='flex items-center justify-center px-6 py-4 mt-4 rounded-md bg-sand ' onClick={handleEmail}><p className='text-xl text-black hover:text-white '>Contact Me </p> <img className='ml-6 w-1/8'  alt="icon" src='./images/emailIcon.png'></img></button>
                        </div>
                        <div className="flex flex-col contactButton">
                            <p className='text-xl text-center md:text-auto'>Click Here to find out if I'm Batman</p>
                            <button className='flex items-center justify-center px-6 py-4 mt-4 rounded-md bg-sand ' onClick={handleDownload}><p className='text-xl text-black hover:text-white'>Download Resume </p> <img className='w-1/6 ml-6' alt="icon" src='./images/download.png'></img></button>
                        </div>
                    </div>   
                </div>   
            </div>  
        </div>
    )
} 
export default About