import { useState } from "react"
import logoFinder from "../Helpers/techHelper"

export default function Projects(props) {
    
    const projects= [
        {
            src: "./images/projects/catering.png",
            title: "Catering Project",
            description: "This site was created for internal useage that allowed staff members to order food within hospitals and other sites, the team of developers were split into 3 groups: testing, front end, backend. I was responsible for front end development which included using Figma to understand the needs of the client and creating multiple UI designs and getting approval from the client and superiors. The devleopment proccess was HTML, CSS, JS as that was the approved tech stack. I ensured that the site was completley functional and responded well to users. I do feel that the entire project may have been simpler if we used front end frameworks",
            techstack: ["Figma", "HTML", "CSS", "JS"]
        },
        {
            src: "./images/projects/asf.png",
            title: "Senior Sports Day Registration",
            description: "",
            techstack: ["HTML", "CSS", "JS", "React", "Nodejs", "Tailwind", "Mongo"]
        },
        {
            src: "./images/projects/migration.png",
            title: "Universtiy Exploration Tool",
            description: "",
            techstack: ["HTML", "CSS", "JS", "React", "NodeJS", "Tailwind", "MySQL", "GCP", "Prisma", "Figma", "SQL"]
        }
    ]
    const [selectedProject, setSelectedProject]  = useState(projects[0])
    
    const handleSelect = (e) => {
        setSelectedProject(projects[Number(e.target.getAttribute('data-index'))]);
    }
    return(
        <div className="flex flex-col w-full h-screen bg-black projectsPage ">
            <span className="flex justify-center w-full pt-24">
                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-4xl text-gold">Projects</h1>
                    <h3 className="mt-4 text-sand">Excellence in Every Line of Code</h3>
                </div>
            </span>
            <div className="hidden mt-10 md:flex md:flex-row md:justify-evenly optionContainer h-1/4">
                    <img src="./images/projects/catering.png" className="object-scale-down cursor-pointer md:h-64 pic rounded-tl-3xl rounded-br-3xl" alt="Catering" data-index={0} onClick={handleSelect}></img> 
                    <img src="./images/projects/asf.png" className="object-scale-down cursor-pointer md:h-64 pic rounded-tl-3xl rounded-br-3xl" alt="ASF " data-index={1} onClick={handleSelect}></img>
                    <img src="./images/projects/migration.png" className="object-scale-down cursor-pointer md:h-64 pic rounded-tl-3xl rounded-br-3xl" alt="Migration " data-index={2} onClick={handleSelect}></img>  
            </div>
            <div className="flex items-center justify-center w-full project-details h-3/6">
               <div className="flex flex-col mt-10 border-2 md:mt-0 md:w-5/6 md:flex-row md:h-3/4 border-sand rounded-tl-3xl rounded-br-3xl">
                    <div className="flex flex-col items-center justify-center px-6 py-4 md:px-auto md:pl-10 md:w-1/4 project-img"> 
                        <img src={selectedProject.src}></img>
                    </div>
                    <div className="flex flex-col justify-between h-full md:w-3/4 project-info ">
                        <div className="w-full">
                            <h1 className="mt-4 text-xl text-center text-gold">{selectedProject.title}</h1>
                        </div>
                        <div className="flex w-full md:pr-4 description">
                            <p className="text-justify text-white">
                                {selectedProject.description}
                            </p>
                        </div>
                        <div className="w-full techStack text-gold">
                            {logoFinder(selectedProject.techstack, "flex  items-center justify-evenly flex-wrap", "flex flex-col items-center justify-center" ,"text-center text-sand animate-pulse")}
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}