var logoData =  {
    csharp: "./images/techstack/csharp.png",
    css: "./images/techstack/css.png",
    dotnet: "./images/techstack/dotnet.png",
    gcp: "./images/techstack/gcp.png",
    html: "./images/techstack/html.png",
    java: "./images/techstack/java.png",
    js: "./images/techstack/js.png",
    mongo:"./images/techstack/mongo.png",
    nodejs:"./images/techstack/nodejs.png",
    php: "./images/techstack/php.png",
    python: "./images/techstack/python.png",
    react: "./images/techstack/react.png",
    sql: "./images/techstack/sql.png",
    tailwind:"./images/techstack/tailwind.png",
    figma: "./images/techstack/figma.png",
    mysql:"./images/techstack/mysql.png",
    prisma: "./images/techstack/prisma.png"
}
export default function logoFinder(logos, stackContentClass, sectionClass, textClass) {

    return(
        <div className={stackContentClass}>
            {
                logos.map(logo => {
                    return(<div  className = {sectionClass}>
                        <img src={logoData[`${logo.toLowerCase()}`]} alt ="icon" />
                        <p className={textClass}>{logo}</p>
                    </div>
                    )
                })
            }
        </div>
    )
}